<script setup lang="ts">
// konfetti Gutscheine supplier ID
import { GtmItemListId } from '~/composables/useKftGtm/types'

const supplierID = 'z38lrx'
const eventsPath = 'v1/store/events'
const supplierEventsParams = `?limit=-1&include=supplier&search=supplier_id:${supplierID};hasEvent:false&orderBy=created_at`
const { data: giftcardEventsData } = await useKftApiFetch(
  `${eventsPath}${supplierEventsParams}`,
  { transform: transformToAlgoliaEvent }
)
defineProps({
  title: {
    type: String,
    default: '',
  },
})
</script>
<template>
  <div>
    <ProductShowcase
      data-test-id="giftcard-events-section"
      :products="giftcardEventsData"
      image-loading="eager"
      :collapse-products="true"
      :title-h-class="`legacy-h2`"
      :mobile-horizontal-scroll="true"
      :title="title"
      :gtm-item-list-id="GtmItemListId.GeneralPageGiftcardEvents"
      :is-duration-visible="false"
      algolia-insights-label="Gift Ideas For You - Homepage"
      class="mt-8 md:mt-16"
    />
  </div>
</template>
